import OfficeContactModal from "@components/OfficeContactModal/OfficeContactModal"
import { contactURL } from "@lib/urls"
import { Link, graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import "./OfficeListing.scss"
import { gtagReportConversion } from "@lib/utils"
import GGFXImage from "@components/elements/GGFXImage"

const OfficeItem = ({ office }) => {
  if (!office) return null

  const { title, address, phone, tile_image, latitude, longitude, slug, ggfx_results, strapi_id } =
    office
  return (
    <div className="office-item">
      <Link to={`${contactURL + slug}/`} className="img-section img-zoom">
        {/* <img src={tile_image.url} alt="office-img" /> */}
        <GGFXImage
          ImageSrc={tile_image}
          altText={title}
          imagetransforms={ggfx_results}
          renderer="srcSet"
          imagename="office.tile_image.tile_img"
          strapiID={strapi_id}
        />
      </Link>
      <div className="about-office">
        <Link to={`${contactURL + slug}/`} className="name">
          {title}
        </Link>
        <p className="address">{address}</p>
        <a
          href={`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`}
          className="maps-link"
          target="_blank"
          rel="noreferrer"
        >
          <span>View on Google Maps</span>
        </a>
      </div>
      <div className="divider" />
      <div className="phone-section">
        <p className="sub-title">Phone</p>
        <a
          href={`tel:${phone}`}
          className="phone"
          onClick={() => gtagReportConversion(`tel:${phone}`)}
        >
          {phone}
        </a>
      </div>
      <div className="divider" />
      <div className="email-section">
        <OfficeContactModal
          office={office}
          triggerChildren="Contact Office"
          // triggerClassName="email"
        />
      </div>
    </div>
  )
}

const OfficeListing = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allStrapiOffice(filter: { publish: { eq: true } }) {
        nodes {
          ...officeFragment
        }
      }
    }
  `)

  const offices = data.allStrapiOffice.nodes

  return (
    <div className="office-listing-wrap section-m">
      <Container className="office-listing-container">
        <div className="office-listing-section">
          {offices.map((office) => (
            <OfficeItem office={office} key={office.id} />
          ))}
        </div>
      </Container>
    </div>
  )
}

export default OfficeListing
