import { useState } from "react"

const usePagination = ({
  items,
  itemsPerPage,
  customCurrentPage,
  customSetCurrentPage,
}) => {
  const [currentPage, setCurrentPage] = useState(1)

  const crrPage = customCurrentPage || currentPage
  const setCurrPage = customSetCurrentPage || setCurrentPage

  const totalItems = items?.length

  // Get current items
  const indexOfLastItem = crrPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = items?.slice(indexOfFirstItem, indexOfLastItem)

  return {
    currentPage: crrPage,
    setCurrentPage: setCurrPage,
    currentItems,
    itemsPerPage,
    totalItems,
  }
}

export default usePagination
